import {
  createStyles,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
  WithStyles,
  withStyles } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import cx from 'classnames';
import { FunctionComponent, useEffect, useState } from 'react';
import { compose } from 'recompose';

export interface AccordionPanelOuterProps {
  header: string;
  content: string;
  isExpanded?: boolean;
}

export type AccordionPanelProps = AccordionPanelOuterProps & WithStyles;

const AccordionPanel: FunctionComponent<AccordionPanelProps> = ({
  classes,
  header,
  content,
  isExpanded,
}) => {

  const [expanded, setExpanded] = useState(isExpanded);

  useEffect(() => {
    if (isExpanded) {
      setExpanded(isExpanded);
    }
  },        [isExpanded]);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  return (
    // tslint:disable-next-line: jsx-no-lambda
    <ExpansionPanel onChange={() => handleChange()} expanded={expanded}>
      <ExpansionPanelSummary
        expandIcon={expanded ? <Remove /> : <Add />}>
        <Typography className={cx([
          classes.header,
          classes.text,
          expanded ? classes.expanded : '',
        ])}>
          {header}
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Typography className={classes.text}>
          <span dangerouslySetInnerHTML={{ __html: content }} className={classes.content}/>
        </Typography>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

const styles = () =>
  createStyles({
    header: {
      fontWeight: 'bold',
    },
    expanded: {
      color: '#990ae1',
    },
    text: {
      fontSize: 14,
    },
    content: {
      '& p' : {
        marginTop: '0',
      },
    },
  });

export default compose<AccordionPanelProps, AccordionPanelOuterProps>(
  withStyles(styles),
)(AccordionPanel);
