import { FunctionComponent } from 'react';
import { Redirect, RedirectProps } from 'react-router-dom';
import { compose } from 'recompose';


export type Redirect404Props = RedirectProps;

const Redirect404: FunctionComponent<Redirect404Props> = ({ ...rest }) => {
  return (
    <Redirect {...rest} />
  );
};

export default compose<Redirect404Props, RedirectProps>()(Redirect404);
