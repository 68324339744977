import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

import '../../assets/styles/teliasans.scss';

// tslint:disable:object-literal-sort-keys
const generic: ThemeOptions = {
  palette: {
    primary: {
      main: '#990ae1',
    },
  },
  overrides: {
    MuiAppBar: {
      colorDefault: {
        boxShadow: 'none',
        backgroundColor: 'transparent',
      },
      root: {
        textAlign: 'center',
      },
    },
    MuiToolbar: {
      root: {
        alignItems: 'center',
        justifyContent: 'inherit',
      },
    },
    MuiButton: {
      root: {
        borderRadius: 27,
        padding: '13px 16px',
        fontWeight: 500,
        maxWidth: 250,
        textTransform: 'none',
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'block',
      },
      contained: {
        boxShadow: 'none',
        color: '#FFFFFF',
        backgroundColor: '#990AE3',
        '&$disabled': {
          backgroundColor: '#00CC66',
        },
        '&$disabled > span': {
          opacity: 0.5,
        },
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: '#00CC66',
        },
      },
      containedSecondary: {
        color: '#990AE3',
        backgroundColor: '#FFFFFF',
        padding: 0,
        '&:hover': {
          backgroundColor: '#FFFFFF',
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        marginRight: 4,
        fontSize: 12,
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: 20,
        width: '1.25em',
        height: '1.25em',
      },
    },
    MuiCardHeader: {
      root: {
        backgroundColor: '#F0F0F0',
        paddingTop: 7,
        paddingBottom: 7,
      },
      action: {
        marginTop: 0,
      },
    },
    MuiCardActions: {
      root: {
        padding: 0,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
      },
      action: {
        paddingTop: 18,
        paddingBottom: 18,
        margin: 0,
        borderRadius: 0,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#E4E4E4',
      },
    },
    MuiTypography: {
      colorTextSecondary: {
        color: '#29003E',
      },
      body1: {
        fontFamily: 'Telia Sans',
        fontSize: 18,
        fontWeight: 400,
        lineHeight: '22px',
      },
      body2: {
        fontFamily: 'Telia Sans',
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '22px',
      },
      h1: {
        fontFamily: 'Telia Sans Bold',
        fontSize: 24,
        fontWeight: 700,
        lineHeight: '27px',
      },
      h2: {
        fontFamily: 'Telia Sans Bold',
        fontSize: 20,
        fontWeight: 700,
        lineHeight: '20px',
      },
      h4: {
        fontSize: 26,
      },
      subtitle1: {
        fontFamily: 'Telia Sans',
        fontSize: 14,
        fontWeight: 400,
        lineHeight: '20px',
      },
      subtitle2: {
        fontFamily: 'Telia Sans',
        fontSize: 12,
        fontWeight: 700,
        lineHeight: '22px',
      },
    },
    MuiExpansionPanel: {
      root: {
        boxShadow: 'none',
        '&:before': {
          backgroundColor: 'none',
        },
      },
      expanded: {
        margin: 0,
        p: {
          color: '#990ae1',
        },
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        minHeight: '55px',
        padding: '0',
      },
      expanded: {
        color: '#990ae1',
      },
      expandIcon: {
        right: '-15px',
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: 'transparent',
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        padding:'8px 8px 8px 0',
      },
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Telia Sans',
      'Roboto',
      'Helvetica',
      'Telia Sans Bold',
      'Helvetica Neue',
      'Arial',
      'sans-serif'].join(
      ',',
    ),
  },
};

export default generic;
