import cx from 'classnames';
import { createStyles, Typography, withStyles, WithStyles } from "@material-ui/core";
import { Fragment, FunctionComponent } from "react";
import { combineStyles } from "../../util/themeProvider";
import { spacing } from '../../constants/theme/spacing';
import { compose } from "recompose";
import alertPng from '../../assets/images/alert.png';

export type NotFoundProps = WithStyles;

const NotFound: FunctionComponent<NotFoundProps> = ({ classes }) => {
  return (
    <Fragment>
      <div className={cx(classes.image, classes.spacing4x)}>
        <img
          src={alertPng}
          alt="alert"
          className={classes.image} />
      </div>
      <Typography
        variant="h1"
        className={cx([classes.spacing4x, classes.heading])}
        align={'left'}>
        Sorry, we did not find anything here
      </Typography>
      <Typography variant="body1" className={cx(classes.spacing2x)} align={'left'}>
        Please check the address.
      </Typography>
    </Fragment>
  );
};

// tslint:disable: object-literal-sort-keys
const styles = () =>
  createStyles({
    image: {
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  });
// tslint:enable: object-literal-sort-keys

export default compose<NotFoundProps, {}>(withStyles(combineStyles(spacing, styles)))(NotFound);
