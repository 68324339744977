import { MuiThemeProvider } from '@material-ui/core/styles';
import { FunctionComponent } from 'react';
import AppRouter from './components/AppRouter';
import themeProvider from './util/themeProvider';

const App: FunctionComponent = () => {
  return (
    <MuiThemeProvider theme={themeProvider()}>
      <AppRouter />
    </MuiThemeProvider>
  );
};

export default App;
