import { FunctionComponent, Suspense } from 'react';
import { Router } from 'react-router-dom';
import history from '../history';
import RoutesConfig from '../RoutesConfig';
import AppContainer from './AppContainer';

const AppRouter: FunctionComponent = () => {
  return (
    <Router history={history}>
      <Suspense fallback={null}>
        <AppContainer>
          <RoutesConfig />
        </AppContainer>
      </Suspense>
    </Router>
  );
};

export default AppRouter;
