import {
  createStyles,
  Typography,
  WithStyles,
  withStyles } from '@material-ui/core';
import cx from 'classnames';
import { Fragment, FunctionComponent, RefObject, useEffect, useRef } from 'react';
import { compose } from 'recompose';
import faqJson from '../../assets/content/faq.json';
import telia_logoPng from '../../assets/images/telia_logo.png';
import { spacing } from '../../constants/theme/spacing';
import { combineStyles } from '../../util/themeProvider';
import AccordionPanel from '../ui/AccordionPanel';
import BackButton from '../ui/BackButton';

export interface RouterProps {
  match: any;
}

export type FAQProps = RouterProps & WithStyles;

const useScroll = (): [() => void, RefObject<HTMLDivElement>] => {
  const elRef = useRef<HTMLDivElement>(null);
  const executeScroll = () => elRef.current?.scrollIntoView();

  return [executeScroll, elRef];
};

const FAQ: FunctionComponent<FAQProps> = ({ classes, match }) => {
  const expandedId = Number(match.params.id);
  const hasHistory = history.length > 2;

  const [executeScroll, elRef] = useScroll();
  useEffect(executeScroll, []);

  const questions = Object.keys(faqJson);
  const answers = Object.values(faqJson);

  return (
    <Fragment>
      <div className={cx([classes.spacing2x, classes.root])}>
        <img src={telia_logoPng} alt="logo" />
      </div>
      <Typography
        variant="h1"
        className={cx([classes.spacing2x])}
        align={'left'}>
        Frequently Asked Questions
      </Typography>

      <div className={classes.spacing}>
        {questions.map((question, i) => (
          <div ref={expandedId === i ? elRef : null} key={i}>
            <AccordionPanel
              header={question}
              content={answers[i]}
              isExpanded={expandedId === i} />
          </div>
        ))}
      </div>

      {hasHistory && <BackButton />}
    </Fragment>
  );
};

// tslint:disable: object-literal-sort-keys
const styles = () =>
  createStyles({
    root: {
      width: '100%',
      '& img': {
        width: '35%',
        maxWidth: '112px',
        margin: '16px auto',
        marginLeft: '0',
        display: 'flex',
      },
    },
  });
// tslint:enable: object-literal-sort-keys

export default compose<FAQProps, RouterProps>(withStyles(combineStyles(spacing, styles)))(FAQ);
