import { createStyles, CssBaseline, WithStyles, withStyles } from '@material-ui/core';
import { FunctionComponent, Suspense } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'recompose';

export type AppContainerProps = RouteComponentProps & WithStyles;

const AppContainer: FunctionComponent<AppContainerProps> = ({ children, classes }) => {
  return (
    <div className={classes.root}>
      <CssBaseline />
      <Suspense fallback={null} />
      <div className={classes.container}>{children}</div>
    </div>
  );
};

const containerClass = {
  margin: '0 auto 25px auto',
  maxWidth: '80%',
};

// tslint:disable: object-literal-sort-keys
const styles = () =>
  createStyles({
    root: {
      maxWidth: '600px',
      minWidth: '340px',
      margin: '0 auto',
      minHeight: '100vh',
      overflow: 'hidden',
      position: 'relative',
    },
    container: {
      ...containerClass,
    },
    tablet: {
      ...containerClass,
      maxWidth: '90%',
      minWidth: '500px',
    },
  });
// tslint:enable: object-literal-sort-keys

export default compose<AppContainerProps, {}>(withRouter, withStyles(styles))(AppContainer);
